<template>
	<Summary
		form_ref="presentQuestionnaireForm"
		:questionnaire_name="$defines.QUESTIONNAIRE.q100"
	></Summary>
</template>

<script>
import Summary from "@/components/research/modules/_common/summary/Index.vue";

export default {
	name: "PresentResearchSummaryPage",

	components: {
		Summary,
	},
};
</script>
